<mat-form-field appearance="outline">
  <mat-label>Pick a date range</mat-label>

  <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
    <input
      matStartDate
      placeholder="Start date"
      [(ngModel)]="selectedStartDate"
      (dateChange)="onDateChange('start')"
    />
    <input
      matEndDate
      placeholder="End date"
      [(ngModel)]="selectedEndDate"
      (dateChange)="onDateChange('end')"
    />
  </mat-date-range-input>

  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>

  <button
    mat-icon-button
    matSuffix
    *ngIf="displayClearBtn"
    (click)="onClearPicker()"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
