import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Auth, User, user, signOut } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'db-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private auth: Auth = inject(Auth);
  user$ = user(this.auth);
  username: string;
  pageTitle: string;
  sub: Subscription;

  constructor(private router: Router, private header: HeaderService) {
    this.user$.subscribe((user: User) => user && (this.username = user.email));
  }

  ngOnInit(): void {
    this.sub = this.header
      .getTitle()
      .subscribe((res: string) => (this.pageTitle = res));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  logout(): void {
    signOut(this.auth);
    this.router.navigate(['/login']);
  }
}
