import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../modules/material.module';

import { MatTableComponent } from './mat-table.component';

@NgModule({
  declarations: [MatTableComponent],
  imports: [CommonModule, MaterialModule],
  exports: [MatTableComponent],
})
export class MatTableModule {}
