import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventsService } from './services/events.service';

@Component({
  selector: 'db-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('dialogTemplate')
  dialogTemplate: TemplateRef<any>;

  constructor(public dialog: MatDialog, private events: EventsService) {}

  ngOnInit(): void {
    this.events.showDialog$.subscribe(() => {
      const options = { width: '400px', height: '200px' };

      this.dialog.open(this.dialogTemplate, options);
    });
  }
}
