import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventsService } from '../services/events.service';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  private auth: Auth = inject(Auth);
  idToken$ = idToken(this.auth);
  requestCount = 0;
  isAuthorized: boolean;

  constructor(
    private spinner: NgxSpinnerService,
    private events: EventsService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requestCount++;

    return this.idToken$.pipe(
      switchMap((token: string) => {
        const header = { Authorization: 'Bearer ' + token };

        if (token) req = req.clone({ setHeaders: header });
        if (!req.url.includes('/packages/sort')) this.spinner.show();

        return next.handle(req).pipe(
          tap(() => (this.isAuthorized = true)),
          catchError((err) => {
            if (err.status === 403) this.isAuthorized = false;

            return of(err);
          }),
          finalize(() => {
            this.requestCount--;
            this.spinner.hide();

            if (this.requestCount === 0 && !this.isAuthorized) {
              this.events.showDialog();
            }
          })
        );
      })
    );
  }
}
