import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'lib-mat-date-range-picker',
  templateUrl: './mat-date-range-picker.component.html',
  styleUrls: ['./mat-date-range-picker.component.scss'],
})
export class MatDateRangePickerComponent implements OnInit {
  hasDefaultDateRange: boolean;
  hasDateRangeInUrl: boolean;
  displayClearBtn = false;

  selectedStartDate: Date;
  selectedEndDate: Date;

  startDateFromUrl: Date;
  endDateFromUrl: Date;

  @Input() bindToUrl: boolean;
  @Input() pickerName: string;
  @Input() defaultStartDate: Date;
  @Input() defaultEndDate: Date;
  @Output() dateChange = new EventEmitter();

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getDateRangeFromUrl();
    this.hasDefaultDateRange = !!(this.defaultStartDate && this.defaultEndDate);

    if (this.hasDefaultDateRange) {
      this.removeTimeFromDefaultDateRange();

      if (this.bindToUrl && !this.hasDateRangeInUrl) {
        this.setDateRangeInUrl(this.defaultStartDate, this.defaultEndDate);
      }

      this.hasDateRangeInUrl
        ? this.setDateRangeInPicker(this.startDateFromUrl, this.endDateFromUrl)
        : this.setDateRangeInPicker(this.defaultStartDate, this.defaultEndDate);
    }
  }

  getDateRangeFromUrl() {
    const { startDate, endDate } = this.route.snapshot.queryParams;

    if (startDate && endDate) {
      this.startDateFromUrl = moment(startDate).toDate();
      this.endDateFromUrl = moment(endDate).subtract(1, 'day').toDate();
      this.hasDateRangeInUrl = true;
    }
  }

  emitDateChange() {
    this.dateChange.emit({
      pickerName: this.pickerName,
      start: this.selectedStartDate,
      end: this.selectedEndDate,
    });
  }

  removeTimeFromDefaultDateRange(): void {
    const removeTime = (date) =>
      moment(date).hours(0).minutes(0).seconds(0).toDate();

    this.defaultStartDate = removeTime(this.defaultStartDate);
    this.defaultEndDate = removeTime(this.defaultEndDate);
  }

  onDateChange(type: string) {
    this.displayClearBtn = true;

    if (type === 'start') {
      this.selectedEndDate = null;
    }

    if (this.selectedStartDate && this.selectedEndDate) {
      this.emitDateChange();

      if (this.bindToUrl) {
        this.setDateRangeInUrl(this.selectedStartDate, this.selectedEndDate);
      }
    }
  }

  onClearPicker(): void {
    this.hasDefaultDateRange
      ? this.setDateRangeInPicker(this.defaultStartDate, this.defaultEndDate)
      : this.clearPicker();

    this.hasDefaultDateRange && this.bindToUrl
      ? this.setDateRangeInUrl(this.defaultStartDate, this.defaultEndDate)
      : this.removeDateRangeFromUrl();

    this.emitDateChange();
  }

  setDateRangeInPicker(start: Date, end: Date): void {
    this.selectedStartDate = start;
    this.selectedEndDate = end;
    this.displayClearBtn = true;
  }

  clearPicker(): void {
    this.selectedStartDate = this.selectedEndDate = null;
    this.displayClearBtn = false;
  }

  setDateRangeInUrl(start: Date, end: Date) {
    this.router.navigate([], {
      queryParams: {
        startDate: moment.utc(start).format(),
        endDate: moment.utc(end).add(1, 'day').format(),
      },
      queryParamsHandling: 'merge',
    });
  }

  removeDateRangeFromUrl(): void {
    this.router.navigate([], {
      queryParams: {
        startDate: null,
        endDate: null,
      },
      queryParamsHandling: 'merge',
    });
  }
}
