import { NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { prop } from 'ramda';
import { PageLayoutComponent } from './layout/page-layout/page-layout.component';
import { AuthGuard } from './core/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/appointment-status',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    component: PageLayoutComponent,
    canActivate: [() => inject(AuthGuard).canActivate()],
    children: [
      {
        path: 'appointment-status',
        loadChildren: () =>
          import('./modules/appointment-status/appointment-status.module').then(
            (m) => m.AppointmentStatusModule
          ),
      },
      {
        path: 'appointment-transactions',
        loadChildren: () =>
          import(
            './modules/appointment-transaction/appointment-transaction.module'
          ).then((m) => m.AppointmentTransactionModule),
      },
      {
        path: 'daily-transactions',
        loadChildren: () =>
          import('./modules/daily-transaction/daily-transaction.module').then(
            (m) => m.DailyTransactionModule
          ),
      },
      {
        path: 'revenue-stream',
        loadChildren: () =>
          import('./modules/revenue-stream/revenue-stream.module').then(
            prop('RevenueStreamModule')
          ),
      },
      {
        path: 'manual-appointments',
        loadChildren: () =>
          import('./modules/manual-appointment/manual-appointment.module').then(
            prop('ManualAppointmentModule')
          ),
      },
      {
        path: 'registered-users',
        loadChildren: () =>
          import('./modules/registered-users/registered-users.module').then(
            prop('RegisteredUsersModule')
          ),
      },
      {
        path: 'doctor-income-reports',
        loadChildren: () =>
          import(
            './modules/doctor-income-report/doctor-income-report.module'
          ).then((m) => m.DoctorIncomeReportModule),
      },
      {
        path: 'roles',
        loadChildren: () =>
          import('./modules/roles/roles.module').then((m) => m.RolesModule),
      },
      {
        path: 'admins',
        loadChildren: () =>
          import('./modules/admins/admins.module').then((m) => m.AdminsModule),
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
