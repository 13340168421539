import { Component, OnInit } from '@angular/core';
import MENUS from '../../core/constants/sidenav-menus';

@Component({
  selector: 'db-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  menus = MENUS;

  constructor() {}

  ngOnInit(): void {}
}
