import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NebularModule } from '../../modules/nebular.module';

import { NbDateRangePickerComponent } from './nb-date-range-picker.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [NbDateRangePickerComponent],
  imports: [CommonModule, FormsModule, NebularModule],
  exports: [NbDateRangePickerComponent],
})
export class NbDateRangePickerModule {}
