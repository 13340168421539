<div class="header">
    <h2 class="header__title">{{ pageTitle | titlecase }}</h2>


    <div class="user">
        <p class="user__name">{{ username }}</p>
        <div class="user__image" [matMenuTriggerFor]="menu"></div>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
    </div>
</div>