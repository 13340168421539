export default [
  {
    label: 'Appointment Status',
    value: '/appointment-status',
    icon: 'analytics',
  },
  {
    label: 'Appointment Transactions',
    value: '/appointment-transactions',
    icon: 'event',
  },
  {
    label: 'Manual Appointments',
    value: '/manual-appointments',
    icon: 'medical_services',
  },
  {
    label: 'Revenue Stream',
    value: '/revenue-stream',
    icon: 'trending_up',
  },
  {
    label: 'Daily Payment Transactions',
    value: '/daily-transactions',
    icon: 'paid',
  },
  {
    label: 'App Register User',
    value: '/registered-users',
    icon: 'group',
  },
  {
    label: 'Doctor Income Report',
    value: '/doctor-income-reports',
    icon: 'credit_card',
  },
  {
    label: 'Role and Permission',
    value: '/admins',
    icon: 'manage_accounts',
  },
  {
    label: 'Role Feature Management',
    value: '/roles',
    icon: 'settings',
  },
];
