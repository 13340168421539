import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../modules/material.module';

import { MatDateRangePickerComponent } from './mat-date-range-picker.component';

@NgModule({
  declarations: [MatDateRangePickerComponent],
  imports: [CommonModule, MaterialModule, FormsModule],
  exports: [MatDateRangePickerComponent],
})
export class MatDateRangePickerModule {}
