import { Router, UrlTree } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { Auth, authState, User } from '@angular/fire/auth';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  private auth: Auth = inject(Auth);
  authState$ = authState(this.auth);

  constructor(private router: Router) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authState$.pipe(
      map((user: User) => (user ? true : this.router.createUrlTree(['/login'])))
    );
  }
}
