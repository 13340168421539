import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbMenuService } from '@nebular/theme';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'lib-nb-date-range-picker',
  templateUrl: './nb-date-range-picker.component.html',
  styleUrls: ['./nb-date-range-picker.component.scss'],
})
export class NbDateRangePickerComponent implements OnInit, OnDestroy {
  /**
   * Bind to defaultStartDate & defaultEndDate to set default range in the picker
   */
  @Input() defaultStartDate: Date;
  @Input() defaultEndDate: Date;

  @Input() bindToUrl = true;
  @Input() placeHolder = 'Select a date range';
  @Input() enableFilterMenu = false;
  @Input() filterMenu: Array<{
    title: string;
    value: string;
    selected: boolean;
  }> = [];

  @Output() dateChange = new EventEmitter();

  selectedDateRange: { start: Date; end: Date };
  selectedFilter: string;

  hasDateRangeInUrl: boolean;
  hasDefaultDateRange: boolean;

  filterMenuSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private nbMenuService: NbMenuService
  ) {}

  ngOnInit(): void {
    const { startDateFromUrl, endDateFromUrl } = this.getDateRangeFromUrl();
    this.hasDateRangeInUrl = !!(startDateFromUrl && endDateFromUrl);

    if (this.hasDateRangeInUrl) {
      this.setDateRangeInPicker(startDateFromUrl, endDateFromUrl);
    }

    if (this.enableFilterMenu) {
      this.selectedFilter = this.filterMenu.find((m) => m.selected).value;
      this.watchFilterSelection();
    }

    this.hasDefaultDateRange = !!(this.defaultStartDate && this.defaultEndDate);

    if (this.hasDefaultDateRange) {
      this.removeTimeFromDefaultDateRange();

      if (!this.hasDateRangeInUrl) {
        this.setDateRangeInPicker(this.defaultStartDate, this.defaultEndDate);

        if (this.bindToUrl) {
          this.setUrlParams(
            this.defaultStartDate,
            this.defaultEndDate,
            this.selectedFilter
          );
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.filterMenuSub) {
      this.filterMenuSub.unsubscribe();
    }
  }

  getDateRangeFromUrl(): { startDateFromUrl: Date; endDateFromUrl: Date } {
    const { startDate, endDate } = this.route.snapshot.queryParams;

    return {
      startDateFromUrl: startDate ? moment(startDate).toDate() : null,
      endDateFromUrl: endDate
        ? moment(endDate).subtract(1, 'day').toDate()
        : null,
    };
  }

  removeTimeFromDefaultDateRange(): void {
    const removeTime = (date) =>
      moment(date).hours(0).minutes(0).seconds(0).toDate();

    this.defaultStartDate = removeTime(this.defaultStartDate);
    this.defaultEndDate = removeTime(this.defaultEndDate);
  }

  setDateRangeInPicker(start: Date, end: Date): void {
    this.selectedDateRange = { start, end };
  }

  /**
   * This method will listen to filter-menu selection and
   * highlight selected option in the filter-menu and
   * add related query params to the url
   */
  watchFilterSelection() {
    this.filterMenuSub = this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'filter-menu'),
        map(({ item }) => item)
      )
      .subscribe((selectedFilter: any) => {
        this.selectedFilter = selectedFilter.value;
        this.highlightSelectedFilter();

        // add selected filter to the url
        if (this.selectedDateRange) {
          this.router.navigate([], {
            queryParams: { filterDateBy: selectedFilter.value },
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  highlightSelectedFilter(): void {
    this.filterMenu.map((m) => (m.selected = m.value === this.selectedFilter));
  }

  setUrlParams(start: Date, end: Date, filterBy: string): void {
    this.router.navigate([], {
      queryParams: {
        startDate: start ? moment.utc(start).format() : null,
        endDate: end ? moment.utc(end).add(1, 'day').format() : null,
        filterDateBy: filterBy,
      },
      queryParamsHandling: 'merge',
    });
  }

  onDateRangeSelected(dates: { start: Date; end: Date }): void {
    const { start, end } = dates;

    if (start && end) {
      this.dateChange.emit({
        start: moment.utc(start).toDate(),
        end: moment.utc(end).add(1, 'day').toDate(),
      });

      if (this.bindToUrl) {
        this.setUrlParams(start, end, this.selectedFilter);
      }
    }
  }

  onClearPicker(): void {
    this.dateChange.emit({
      start: this.hasDefaultDateRange ? this.defaultStartDate : null,
      end: this.hasDefaultDateRange ? this.defaultEndDate : null,
    });

    this.hasDefaultDateRange
      ? this.setDateRangeInPicker(this.defaultStartDate, this.defaultEndDate)
      : (this.selectedDateRange = null);

    if (this.bindToUrl) {
      this.setUrlParams(
        this.hasDefaultDateRange ? this.defaultStartDate : null,
        this.hasDefaultDateRange ? this.defaultEndDate : null,
        this.hasDefaultDateRange ? this.selectedFilter : null
      );
    }
  }
}
