import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class CsvExport {
  selectedDateRanges: {};

  protected constructor() {}

  getExportData(group): Observable<any> {
    if (
      !this.selectedDateRanges[group] ||
      (!this.selectedDateRanges[group].start &&
        !this.selectedDateRanges[group].end)
    ) {
      window.alert('Please select the date range to export');
      return of();
    }

    const { start, end } = this.selectedDateRanges[group];

    return this.loadDataForCSV({
      transactionType: group.toUpperCase(),
      start,
      end,
      all: true,
    }).pipe(
      map((res: { data: Array<object>; count: number }) =>
        res.data.map(this.reshapeDataForTable)
      )
    );
  }

  csvFn(group) {
    return () => this.getExportData(group);
  }

  abstract loadDataForCSV(...params);
  abstract reshapeDataForTable(data);
}
