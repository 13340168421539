import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbMomentDateModule } from '@nebular/moment';
import {
  NbButtonModule,
  NbLayoutModule,
  NbCardModule,
  NbInputModule,
  NbUserModule,
  NbActionsModule,
  NbIconModule,
  NbDialogModule,
  NbSelectModule,
  NbTooltipModule,
  NbDatepickerModule,
  NbTimepickerModule,
  NbRadioModule,
  NbTabsetModule,
  NbListModule,
  NbCheckboxModule,
  NbFormFieldModule,
  NbContextMenuModule,
  NbMenuModule,
  NbSpinnerModule,
  NbToggleModule,
  NbToastrModule,
  NbTreeGridModule,
} from '@nebular/theme';

const nbModules = [
  NbButtonModule,
  NbLayoutModule,
  NbCardModule,
  NbInputModule,
  NbUserModule,
  NbActionsModule,
  NbIconModule,
  NbDialogModule,
  NbSelectModule,
  NbTooltipModule,
  NbDatepickerModule,
  NbTimepickerModule,
  NbRadioModule,
  NbTabsetModule,
  NbListModule,
  NbCheckboxModule,
  NbFormFieldModule,
  NbContextMenuModule,
  NbMenuModule,
  NbMomentDateModule,
  NbEvaIconsModule,
  NbSpinnerModule,
  NbToggleModule,
  NbToastrModule,
  NbTreeGridModule,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...nbModules],
  exports: [...nbModules],
})
export class NebularModule {}
