<button
  *ngIf="uiLibrary === 'material'"
  mat-flat-button
  color="primary"
  (click)="exportCsv()"
>
  <mat-icon>download</mat-icon>&nbsp;Export CSV
</button>

<button
  *ngIf="uiLibrary === 'nebular'"
  nbButton
  status="success"
  size="small"
  (click)="exportCsv()"
>
  <i class="fas fa-file-csv"></i>&nbsp;Export CSV
</button>
