<ng-container *ngIf="dataSource && dataSource.length; else noData">
  <table mat-table [dataSource]="dataSource">
    <ng-container
      *ngFor="let col of tableColumnHeaders"
      [matColumnDef]="col.columnDef"
    >
      <th mat-header-cell *matHeaderCellDef class="column-header">
        {{
          col.original
            ? col.label
            : col.uppercase
            ? (col.label | uppercase)
            : (col.label | titlecase)
        }}
      </th>

      <td mat-cell *matCellDef="let element">
        <div *ngIf="col.isTemplateColumn; else textCell">
          <ng-container
            *ngTemplateOutlet="
              element[col.columnDef].template;
              context: element[col.columnDef].context
            "
          >
          </ng-container>
        </div>

        <ng-template #textCell>
          {{ element[col.columnDef] }}
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    *ngIf="enablePagination"
    [pageSizeOptions]="[5, 10, 20]"
    [length]="count"
    (page)="onPageChange($event)"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [hidePageSize]="hidePageSize"
    showFirstLastButtons="true"
  ></mat-paginator>
</ng-container>

<ng-template #noData>
  <div class="no-data">
    <img class="no-data-image" *ngIf="noDataImageUrl" [src]="noDataImageUrl" />
    <p class="no-data-message">no data available</p>
  </div>
</ng-template>
