<mat-sidenav-container class="sidenav-container">
  <mat-sidenav mode="side" opened class="sidenav">
    <db-sidenav></db-sidenav>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content">
    <db-header></db-header>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
