<router-outlet></router-outlet>

<ng-template #dialogTemplate>
  <div class="dialog">
    <p>You do not have permission to view this page</p>
    <button mat-dialog-close mat-flat-button color="primary">OK</button>
  </div>
</ng-template>

<ngx-spinner type="ball-beat">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
