<nb-form-field>
  <button
    *ngIf="enableFilterMenu"
    nbButton
    nbPrefix
    ghost
    [nbContextMenu]="filterMenu"
    nbContextMenuTag="filter-menu"
    nbContextMenuPlacement="left"
  >
    <nb-icon icon="funnel" nbTooltip="Filter by"></nb-icon>
  </button>

  <input
    [placeholder]="placeHolder"
    nbInput
    [nbDatepicker]="nbdatepicker"
    [(ngModel)]="selectedDateRange"
  />

  <button
    *ngIf="selectedDateRange"
    nbButton
    nbSuffix
    ghost
    (click)="onClearPicker()"
  >
    <nb-icon icon="close-outline"></nb-icon>
  </button>
</nb-form-field>

<nb-rangepicker
  #nbdatepicker
  (rangeChange)="onDateRangeSelected($event)"
></nb-rangepicker>
