import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../modules/material.module';
import { NebularModule } from '../../modules/nebular.module';

import { ExportCsvComponent } from './export-csv.component';

@NgModule({
  declarations: [ExportCsvComponent],
  imports: [CommonModule, MaterialModule, NebularModule],
  exports: [ExportCsvComponent],
})
export class ExportCsvModule {}
