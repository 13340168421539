import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

interface TableData {
  data: Array<object>;
  count: number;
}

interface ColumnHeader {
  columnDef: string;
  label: string;
  uppercase?: boolean;
  original?: boolean;
  isTemplateColumn?: boolean;
}

@Component({
  selector: 'lib-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss'],
})
export class MatTableComponent implements OnChanges, OnInit {
  dataSource: Array<object>;
  count: number;
  displayedColumns: string[];

  /**
   * PAGINATION RELATED STUFFS.
   * pageIndex is required to reset by the parent to avoid paginator bug when
   * tableData is changed by events of other components such as date picker and searchbox
   */
  @Input() enablePagination: boolean; // Optional
  @Input() pageIndex = 0;
  @Input() pageSize = 10;
  @Input() hidePageSize = false;
  @Output() pageChange = new EventEmitter();

  @Input() tableName: string; // Optional
  @Input() tableData: TableData;
  @Input() tableColumnHeaders: Array<ColumnHeader>;

  @Input() noDataImageUrl: string;

  ngOnInit(): void {
    this.displayedColumns = this.tableColumnHeaders.map((col) => col.columnDef);
  }

  ngOnChanges(changes) {
    if (changes.tableData) {
      this.dataSource = changes.tableData.currentValue?.data;
      this.count = changes.tableData.currentValue?.count;
    }

    if (changes.pageIndex) {
      this.pageIndex = changes.pageIndex.currentValue;
    }
  }

  onPageChange({ pageSize, pageIndex }): void {
    this.pageChange.emit({
      tableName: this.tableName,
      limit: pageSize,
      skip: pageIndex * pageSize,
      pageIndex,
    });
  }
}
