import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventsService {
  private _showDialog = new Subject<boolean>();
  showDialog$ = this._showDialog.asObservable();

  showDialog(): void {
    this._showDialog.next(true);
  }
}
