import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private headerTitle = new Subject<string>();

  constructor() {}

  getTitle() {
    return this.headerTitle.asObservable();
  }

  setTitle(title: string) {
    this.headerTitle.next(title);
  }

  removeTitle() {
    this.headerTitle.next('');
  }
}
