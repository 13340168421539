export const BookingStatuses = {
  1: 'PENDING',
  PENDING: 1,
  2: 'APPROVED',
  APPROVED: 2,
  3: 'REJECTED',
  REJECTED: 3,
  4: 'CANCELLED',
  CANCELLED: 4,
  5: 'COMPLETE',
  COMPLETE: 5,
  6: 'CANCELLED_BY_SYSTEM',
  CANCELLED_BY_SYSTEM: 6,
  7: 'INCOMPLETE',
  INCOMPLETE: 7,
};
