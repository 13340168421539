<nav class="sidenav">
  <div class="sidenav__heading">
    <div class="sidenav__heading-logo"></div>
    <h1 class="sidenav__heading-title">Myancare</h1>
  </div>

  <div class="sidenav__menu">
    <ul class="sidenav__menu-list">
      <li class="sidenav__menu-item" *ngFor="let m of menus">
        <a
          class="sidenav__menu-link"
          routerLinkActive="sidenav__menu-link--active"
          [routerLink]="m.value"
        >
          <mat-icon>{{ m.icon }}</mat-icon>
          <span class="sidenav__menu-link-icon">{{ m.label }}</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
