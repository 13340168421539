import { Component, Input } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-export-csv',
  templateUrl: './export-csv.component.html',
  styleUrls: ['./export-csv.component.scss'],
})
export class ExportCsvComponent {
  /**
   * A method to get export data must be supplied by the parent
   */
  @Input() dataFn: () => Observable<object[]>;
  @Input() headers: Array<string>;
  @Input() fileName: string;
  @Input() uiLibrary; // 'material' or 'nebular'

  constructor() {}

  exportCsv(): void {
    this.dataFn().subscribe(
      (data) => {
        if (!data.length) {
          return window.alert('No data to export');
        }

        const csv = new ngxCsv(data, this.fileName, {
          headers: this.headers,
        });
      },
      (err) => {
        window.alert('Something went wrong while getting export data');
      }
    );
  }
}
