export function setDefaultToEmptyFields(obj: object, defVal: string): object {
  Object.keys(obj).map((k) => {
    if (!obj[k]) obj[k] = defVal;
  });
  return obj;
}

export function removeEmptyFields(obj: object): object {
  Object.keys(obj).forEach((k) => {
    if (obj[k] === null || obj[k] === undefined || obj[k] === '') delete obj[k];
  });
  return obj;
}

export function getKeyByValue(obj: object, val: string): string {
  return Object.keys(obj).find((k) => obj[k] === val);
}
